import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Content from '../../HubSpot/Benefits/style'
export default function Overview(){
return(
<Content>
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xxl-12 col-xl-12 col-lg-12">
        <Content.Content>
          
          <Content.ContentBox>
            <Content.TitleSmall as="h3">Elevate Your Outreach with We-Connect and Salesforce</Content.TitleSmall>
            <Content.Text>• Managing your LinkedIn outreach alongside other marketing and sales efforts can often feel disjointed—working in multiple platforms, updating contact details manually, and struggling to track campaign progress in a single place. That’s where We-Connect’s Salesforce integration comes in.</Content.Text>
            <Content.Text>• Our integration brings your team’s LinkedIn outreach directly into Salesforce, ensuring that your entire sales and marketing team has access to the most up-to-date information. By syncing contact data and campaign metrics between platforms, you can unify your outreach strategy, making it easier for your team to collaborate, stay aligned, and act on real-time insights.</Content.Text>
            <Content.Text>• Gone are the days of switching between tools or worrying about fragmented data. With our two-way sync, your team will have a single source of truth for managing LinkedIn campaigns, tracking performance, and enriching contact databases—all within the familiar Salesforce dashboard. This integration allows you to streamline your sales pipeline, ensuring that every prospect is engaged with timely and personalized messaging, and no opportunity falls through the cracks.</Content.Text>
            <Content.Text>• Whether you’re managing LinkedIn connections, running outreach campaigns, or nurturing relationships, We-Connect’s Salesforce integration simplifies the process. It ensures that all your LinkedIn data is instantly accessible and actionable in Salesforce, empowering your team to stay in sync, make faster decisions, and drive results.</Content.Text>
          </Content.ContentBox>
          
        </Content.Content>
      </Col>
    </Row>
  </Container>
</Content>

)
}