import React from 'react'
import Content from '../../../Sales/Styles/styleGrad'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "~assets/image/media/AiAssist/svg/Generate-Message.svg";

export default function GradFour({}){

return(
<Content className='grad'>
  
  <Container>
    <Content.Block>
    <Row className="justify-content-center text-center">
    <Col xs="12">
    <Content.Title as="h2"> <SuperTag value="Unify your marketing & sales efforts today"/> </Content.Title>
    <Content.Button target="_blank" to="//account.we-connect.io/signup" className="btn btn-white">Power up your pipeline with We-Connect and Salesforce</Content.Button>
      </Col>
    </Row>
    </Content.Block>

    
  </Container>
</Content>
)
}