import React, { useState } from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import { Modal, ModalContent } from "../../HubSpot/Benefits/Modal";
import Content from '../../../Sales/Styles/styleTwo'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "~assets/image/media/Salesforce/Salesforce-Feature3.png";

export default function Enrich({}){
  const [isOpen, setIsopen] = useState(false);
  const showModal = () => setIsopen((prev) => !prev);
return(
<Content>
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
      
        <Content.TextBlock>
          <Content.SubTitle as="h2">Stay on top of your outreach with alerts</Content.SubTitle>

          <Content.Text> <SuperTag value="Stay in the loop with real-time updates on your LinkedIn outreach. Receive notifications for accepted connection requests, replies, and message opens, so you can act quickly to nurture leads and close deals faster."/> </Content.Text>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
      
        <Content.Image className="holder"> 
        <img width="660" className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
        
        {/* {isOpen && (
        <ModalContent onClose={() => setIsopen(false)}>
          <img className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </ModalContent>
      )} */}
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>


)
}