import styled from 'styled-components/macro';
import { Link } from '~components';
import { Box, Heading,Button,Paragraph } from '~styled';

const Hero = styled(Box)`
    padding-top: 100px;
    padding-bottom: 60px;
    position: relative;

    @media (min-width: 768px){
        padding-top: 140px;
        padding-bottom: 60px;
    }

    @media (min-width: 992px){
        padding-top: 150px;
        padding-bottom: 60px;
    }

    @media (min-width: 1200px){
        padding-top: 170px;
        padding-bottom: 60px;
    }

    @media (min-width: 1366px){
        padding-top: 160px;
        padding-bottom: 160px;
    }

`


Hero.Title = styled(Heading)`
font-weight: 500;
letter-spacing: -2px;
line-height: 1.1;
font-size: 40px;
margin-bottom: 25px;
z-index: 1;
position: relative;

@media (min-width: 480px) {
    font-size: 40px;
}

@media (min-width: 768px){
    font-size: 50px;
}

@media (min-width: 992px){
    font-size: 60px;
}
`
Hero.TypeEffect = styled(Heading)`
font-weight: 500;
letter-spacing: -2px;
line-height: 1.1;
font-size: 30px;
margin-bottom: 25px;
z-index: 1;
position: relative;

@media (min-width: 480px) {
    font-size: 30px;
}

@media (min-width: 768px){
    font-size: 40px;
}

@media (min-width: 1200px){
    font-size: 40px;
}
`
Hero.TitleShape = styled(Box)`
position: absolute;
left: 0;
top: 0;
z-index: 0;
width: 18.5%;

@media (min-width: 768px){
    left: 5%;
    top: -5%;
}

@media (min-width: 992px){
    left: 6%;
    top: -22%;
}
`

Hero.Text = styled(Paragraph)`
color: var(--color-texts-3);
font-size: 18px;
font-weight: 400;
letter-spacing: normal;
line-height: 34px;

@media (min-width: 768px){
    font-size: 21px;
}
`

Hero.ContentBlock = styled(Box)`
    
`
Hero.InputBox = styled(Box)`
position:relative;
.form-control{
    width:100%;
    border-radius:500px;
    min-height:50px;
    padding-left:30px;
    @media (min-width:480px){
        min-height:72px;
    }
}
`
Hero.Button = styled(Link)`
    border-radius: 10px;
    padding: 16px 35px;
    margin-top:10px;
    min-width:100%;
    @media (min-width:480px){
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        margin-top:0px;
    }

`
Hero.Image = styled(Box)`
position: relative;
margin-bottom: 40px;
width: auto;
height: auto;
@media (min-width: 992px){
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    width: 348px;
    height: 348px;
}
@media (min-width: 1200px){
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 516px;
  height: 543px;
  padding-left: 20px;
}
    

`
Hero.ImageShape01 = styled(Box)`
position: absolute;
right: -3.5%;
bottom: 35%;
z-index: 0;
width: 9.7%;
`
Hero.ImageShape02 = styled(Box)`
position: absolute;
left: -4%;
bottom: 20%;
z-index: 0;
width: 10%;
`
Hero.NewsletterBox =styled(Box)`
border-radius: 75px;
max-width: 516px;
margin: 0;

@include mobile {
  background-color: #f2f5fb;
  border: 2px solid #f8f9fc;
}

`
Hero.Newsletter =styled(Box)`

`
Hero.Box = styled(Box)`

`
Hero.NewsletterText = styled(Paragraph)`
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 28px;
    margin-top: 18px;
    

`
export default Hero;