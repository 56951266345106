import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import HeroSection from "~sections/LandingPages/Salesforce/HeroSection"
import Testimonial from "~sections/customer-stories/Testimonial"
import { Helmet } from "react-helmet"
import Manage from "~sections/LandingPages/Salesforce/Benefits/manage"
import Measure from "~sections/LandingPages/Salesforce/Benefits/Measure"
import Track from "~sections/LandingPages/Salesforce/Benefits/track"
import Enrich from "~sections/LandingPages/Salesforce/Benefits/enrich"
import GradThree from "~sections/LandingPages/Salesforce/Benefits/grad"
import Overview from "~sections/LandingPages/Salesforce/Benefits/overview"
import Tools from "~sections/LandingPages/Salesforce/Benefits/integration-tools"
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Salesforce() {
  return (
    <>
    <Helmet>
    <title>We-Connect | Salesforce Integration</title>
    <meta name="description" content="Streamline and scale your LinkedIn outreach with We-Connect’s native Salesforce integration. Manage LinkedIn activity directly from Salesforce for a unified pipeline."/>
    <meta property="og:title" content="Integrate Salesforce & We-Connect automated LinkedIn outreach "/>
    <meta property="og:description" content="Streamline and scale your LinkedIn outreach with We-Connect’s native Salesforce integration. Manage LinkedIn activity directly from Salesforce for a unified pipeline. "/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/salesforce-integration"/>

    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="Integrate Salesforce & We-Connect automated LinkedIn outreach "/>
    <meta property="twitter:description" content="Streamline and scale your LinkedIn outreach with We-Connect’s native Salesforce integration. Manage LinkedIn activity directly from Salesforce for a unified pipeline."/>
    <meta property="twitter:image" content="https://we-connect.io/we-connect-salesforce-integration.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <HeroSection/>
      <Overview />
      <Measure />
      <Track />
      <Enrich />
      <Manage />
      <Tools />
      <GradThree />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
