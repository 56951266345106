import styled from 'styled-components/macro';
import {
    Box,
    Heading,
    Button,
    Paragraph
} from '~styled';

const Content = styled(Box)
`
padding-top: 20px;
padding-bottom: 20px;

@media (min-width: 768px){
    padding-top: 60px;
    padding-bottom: 60px;
}
@media (min-width: 992px){
    padding-top: 100px;
    padding-bottom: 60px;
}


`
Content.Title = styled(Heading)
`
font-size: 32px;
font-weight: 500;
letter-spacing: normal;
line-height: 1.4423;
margin-bottom: 60px;
margin-top: 60px;

@media (min-width: 576px) {
  font-size: 25px;
}
@media (min-width: 768px)
{
  font-size: 32px;
}
@media (min-width: 992px)
{
  font-size: 32px;
  margin-bottom: 100px;
}
`
Content.TitleSmall = styled(Heading)
`
font-size: 24px;
color: #262729;
font-weight: 500;
letter-spacing: normal;
line-height: 1.42857;
margin-bottom: 15px;
@media (min-width: 992px){
    margin-bottom: 28px;
}
`
Content.Text = styled(Paragraph)
`
font-size: 16px;
color: #262729;
font-weight: 400;
letter-spacing: normal;
line-height: 1.777777;
@media (min-width: 992px){
    font-size: 17px;
}
  span{
    font-size: 14px;
  }
`
Content.List = styled(Box)
``


Content.ListItem = styled(Paragraph)
`
font-size: 16px;
font-weight: 400;
letter-spacing: normal;
line-height: 1.777777;
@media (min-width: 992px){
    font-size: 18px;
}
`
Content.ContentBox = styled(Box)
`
padding-top: 10px;
padding-bottom: 20px;

    table {

        border-spacing: 0;
        border: 1px solid black;
    
        tr {
          :last-child {
            td {
              border-bottom: 0;
            }
          }
        }
    
        th,
        td {
          margin: 0;
          padding: 1.5rem;
          border-bottom: 1px solid black;
          border-right: 1px solid black;
          color: #000;
    
          :last-child {
            border-right: 0;
          }
        }
      }

`
Content.Content = styled(Box)
``


Content.Box = styled(Box)
``


export default Content;